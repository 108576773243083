import React, { useEffect, useState } from "react";
import "./Registermandate.scss";
import { EnachStatusCode } from "../../../constants/Common/commonConstants";
import { useParams } from "react-router-dom";
import logo from "../../../Images/TCL-newlogo.jpg";
import logoImage from "../../../Images/eNachNPCIlogo.png";
import apiCall from "../../../utils/apiFunction/apiCall";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { apiConstants, enachTechProcessConstants, registerMandateManualAttribute, tableNamePaymentConstants , toastIds ,   errMessage,
} from "../../../constants/Common/apiConstants";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";


const ResponseStatus = () => {
  const [errorFlag, setErrorFlag] = useState(0);
  const [response, setResponse] = useState({});
  const [timerEnd , setTimerEnd] = useState(false);
  const [auth , setAuth] = useState(false);
  

  const { key} = useParams();
  const ws = useSelector((state)=> state.webSocketReducer.webSocket)
  ws.onopen =() => {console.log("----- connected ");}


  const fetchResponseStatus = () => {
    const params = {
      tablename: tableNamePaymentConstants?.ManualMandate,
      whereQuery: { bitly_sms_status: key },
      attributes: registerMandateManualAttribute,
    };
    console.log("------------------ before calling API ----------------");
    apiCall
      .post(apiConstants?.findData, params)
      .then((rendeResponse) => {
        if(rendeResponse.status === 200){
          setResponse(rendeResponse.data.response[0])
          if((rendeResponse.data.response[0].enach_mandate_id === "") && EnachStatusCode.MANDATE_STATUS_CODES.includes(rendeResponse.data.response[0].mandate_status) ) {
            console.log("------------------> rendeResponse.data.response[0]",rendeResponse.data.response[0]);
            if(rendeResponse.data.response[0].icici_execution_one_rs_bankRRN === null && rendeResponse.data.response[0].status !== 'VALIDATION-FAILURE'){
            alert('Your link has been expired as you have already completed your ENACH Journey.')
            window.open("about:blank", "_self");
          }
          else{
            console.log("------------------> rendeResponse.data.response[0] else.......",rendeResponse.data.response[0]);

            const params = {key : key}
            apiCall.post(enachTechProcessConstants?.upiResponseStatus , params)
                .then((response) => {
                  if(response?.status === 200){
                    if(response?.data?.status === 200){
                      return window.location.href = response?.data?.URL
                    }
                  }
                }).catch((error) => {
                  toast.error(error?.message || errMessage?.InternalServerError, {
                    toastId: toastIds?.InternalServerError,
                  });
                })
          }
          }
          else {
            setAuth(true)
            console.log("------------------ after calling API ----------------",ws);
            console.log("------------> rendeResponse",rendeResponse);
        const ws1= new WebSocket(`${enachTechProcessConstants?.webSocketForWebhook}`);
            
            ws1.onopen = () => {
              console.log('WebSocket connected---------------- >'); 
            };
        
            ws1.onmessage = (event) => {
              const data = JSON.parse(event.data);
              if(data.payload ===  200 && data.key === key) {
                const params = {key : key}
                apiCall.post(enachTechProcessConstants?.upiResponseStatus , params)
                .then((response) => {
                  if(response?.status === 200){
                    if(response?.data?.status === 200){
                      return window.location.href = response?.data?.URL
                    }
                  }
                }).catch((error) => {
                  toast.error(error?.message || errMessage?.InternalServerError, {
                    toastId: toastIds?.InternalServerError,
                  });
                })
              }
            };
        
            ws1.onclose = () => {
              console.log('WebSocket closed');
            };
        
            return () => {
              if (ws.readyState === 1) {
                ws.close();
            }
            };
          }
        }

       

      })
      .catch((error) => {
        return setErrorFlag(1);
      });
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setTimerEnd(true);
      timeUp();
    } 


    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    return (
      <div className="timer">
        <div className="timerVal ml-4 value">
          <b> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</b>
        </div>
      </div>
    );
  };

  const timeUp = async()=>{
    if(timerEnd){
      const response = await apiCall
      .post(apiConstants?.updateUser, {
        tablename: tableNamePaymentConstants?.ManualMandate,
        whereQuery: { bitly_sms_status: key ,mandate_status : null,customer_transaction_flag:1},
        body: {mandate_status :'0399',response_rejection_reason:"Your session has expired."},
      })
      if(response.status == 200){
        const params = {key : key}
        apiCall.post(enachTechProcessConstants?.upiResponseStatus , params)
        .then((response) => {
          if(response?.status === 200){
            if(response?.data?.status === 200){
              return window.location.href = response?.data?.URL
            }
          }
        }).catch((error) => {
          toast.error(error?.message || errMessage?.InternalServerError, {
            toastId: toastIds?.InternalServerError,
          });
        })
      }
    }
  }

  useEffect(() => {
  
    fetchResponseStatus()

  }, []);

  return (
    <>
    {
      auth === true && <div className="bg">
      <div>
        <nav className="main_navbar d-flex justify-content-center justify-content-lg-end">
          <h5 className="mr-2 mt-1">
            {response.company_code ===
              EnachStatusCode.FINANCIAL_COMPANY_CODE ? (
              <div>
                {" "}
                <i className="fa fa-envelope"></i>{" "}
                <label className="mr-2 text-right">
                  customercare@tatacapital.com
                </label>
                <i className="fa fa-phone "></i> <label>1860 267 6060</label>{" "}
              </div>
            ) : (
              <div>
                {" "}
                <i className="fa fa-envelope"></i>{" "}
                <label className="mr-2 text-right">
                  customercare.housing@tatacapital.com
                </label>
                <i className="fa fa-phone "></i> <label>1860 267 6060</label>
              </div>
            )}
          </h5>
        </nav>
        <nav className="mynavbar navbar">
          <div className="container d-flex justify-content-center justify-content-lg-between">
            <img className="logo" src={logo} alt="logo" />
            <img className="secondimg" src={logoImage}></img>
          </div>
        </nav>
      </div>
      <div className="container row justify-content-center d-flex mx-auto ">
        <div className="row d-flex p-4 justify-content-center timer-wrapper ">
          <CountdownCircleTimer
            isPlaying
            duration={600}
            colors={["#006400", "#A30000", "#A30000"]}
            colorsTime={[6, 3, 0]}
            onComplete={() => ({ shouldRepeat: false, delay: 1 })}
          >
            {renderTime}
          </CountdownCircleTimer>
        </div>

        {
          timerEnd === true ?

          <div className="note d-flex justify-content-center pt-4">
          <p className="NoteText text-center">
            Session Expired.<br />
          </p>
        </div>

          :

          <div className="note d-flex justify-content-center pt-4">
          <p className="NoteText text-center">
            Payment Request has been sent to your UPI App.<br />
            Note: You will be redirected to another page once your payment is processed.Do not close this page or press back.
          </p>
        </div>


        }

      </div>

      <nav className="footer navbar d-flex justify-content-center">
        <label>Copyright © 2020 Tata Capital</label>
      </nav>
    </div>
    }
      

    </>
  );
};

export default ResponseStatus;
