import React from 'react';
import { ListGroup, Container, Row, Col } from 'react-bootstrap';
import './ContractStatusViewer.scss';

const StatusTracker = ({ data }) => {
  // Define steps based on the provided data
  const steps = [
    {label: 'Payment Entry Received From LMS To SystemX', date: data.DisbursementFromLMSToSystemX, isComplete: data.DisbursementFromLMSToSystemX && data.DisbursementFromLMSToSystemX !== "—", color: 'bg-success' },
    {
      label: data.Bucket === 'Rejected by Banking Ops' ? 'Payment Rejected Date In SystemX' : 'Payment Author Date In SystemX' , 
      date: data.Bucket === 'Rejected by Banking Ops' ? data.DisbursementUpdatedAt : data.SystemXSendToSponsorBank, 
      isComplete: data.Bucket === 'Rejected by Banking Ops' ? (data.DisbursementUpdatedAt && data.DisbursementUpdatedAt !== '—') : (data.SystemXSendToSponsorBank && data.SystemXSendToSponsorBank !== "—") , 
      color: 'bg-success'},
  ];

  if(data.Bucket !== 'Rejected by Banking Ops') {
    steps.push({label: `Final Status from Bank: ${data.FinalStatusFromBank && data.FinalStatusFromBank !== '—' ? data.FinalStatusFromBank : ''}`, date: data.SystemXSendToSponsorBank, isComplete: data.FinalStatusFromBank && data.FinalStatusFromBank !== "—", color: data.FinalStatusFromBank === 'Failed' ? 'bg-danger' : 'bg-success' })
  }

  // Conditionally add final steps based on the FinalStatusFromBank value
  switch (data.FinalStatusFromBank) {
    case 'Success':
      steps.push({
        label: `UTR Generated: ${data.UTR}`,
        date: data.UTRGeneratedDate,
        isComplete: data.UTRGeneratedDate && data.UTRGeneratedDate !== "—",
        color: 'bg-success'
      });
      break;
      
    // case 'Failed':
    //   steps.push({
    //     label: `Final Status from Bank: ${data.FinalStatusFromBank && data.FinalStatusFromBank !== '—' ? data.FinalStatusFromBank : ''}`, 
    //     date: data.SystemXSendToSponsorBank, 
    //     isComplete: data.FinalStatusFromBank && data.FinalStatusFromBank !== "—", 
    //     color: data.FinalStatusFromBank === 'Failed' ? 'bg-danger' : 'bg-success' 
    // })

      // steps.push({
      //   label: 'Reason for Case Rejection',
      //   date: (data.ReasonOfCaseRejection && data.ReasonOfCaseRejection !== '—') ? data.ReasonOfCaseRejection : 'Failed',
      //   isComplete: true,
      //   color: 'bg-danger'
      // });
      
      // steps.push({
      //   label: `UTR Generated: ${data.UTR}`,
      //   date: (data.UTR && data.UTR !== '—' ) ? data.UTRGeneratedDate : '',
      //   isComplete: data.UTR && data.UTR !== '—' ,
      //   color: 'bg-danger'
      // });
      // break;
  
    case 'Under Processing':
      steps.push({
        label: `UTR Generated: ${data.UTR && data.UTR !== "—" ? data.UTR : ''}`,
        date: data.UTRGeneratedDate,
        isComplete: data.UTRGeneratedDate && data.UTRGeneratedDate !== "—"
      });
      break;
  
    default:

    if(data.FinalStatusFromBank !== 'Failed' && data.Bucket !== 'Rejected by Banking Ops') {
      steps.push({
        label: `UTR Generated: ${data.UTR && data.UTR !== "—" ? data.UTR : ''}`,
        date: data.UTRGeneratedDate,
        isComplete: data.UTRGeneratedDate && data.UTRGeneratedDate !== "—",
      });
    }
      // return <></>;
  }
  return (
    <div className="border rounded my-2 mx-4 bg-white">
      <div className="main-tracker-container p-4 container">
        <Row>
          <Col md={12}>
            <ListGroup>
              {steps.map((step, index) => (
                <ListGroup.Item
                  key={index}
                  className="d-flex flex-column justify-content-center p-0 border-0"
                >
                  <div className="stage-container d-flex align-items-center">
                    <div
                      className={`step-marker d-flex align-items-center justify-content-center ${
                        step.isComplete && step.color ? `${step.color} text-white` : "bg-secondary-subtle text-dark"
                      }`}
                    >
                      {index + 1}
                    </div>
                    <div className="ms-3">
                      <p className={`mb-0 ${step.isComplete ? '' : 'text-muted'}`}>{step.label}</p>
                      <small className="text-muted">{step.date}</small>
                    </div>
                  </div>
                  {/* Connector between steps */}
                  {index < steps.length - 1 && (
                    <div
                      className="stage-connector my-1"
                      style={{ height: "60px" }}
                    >
                      <div
                        className={`w-25 border-start border-2 ${
                          steps[index + 1].isComplete
                            ? "border-success-subtle"
                            : "border-secondary-subtle"
                        } h-100`}
                        style={{ marginLeft: "19px" }}
                      ></div>
                    </div>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StatusTracker;
