export const apiConstants = {
  ScanMergeStatusDownloadMergeStatusReport: '/reports/scanBaseReport/downloadMergeStatusReport',
  ScanSftpMergeStatusDownloadReport: 'reports/scanBaseReport/downloadFile?file_name=',
  downloadManualDT: "DebitTransaction/DeMerge/downloadManualSourceSystem",
  manualMarkingChangeStatus: "api/payment/manualMaking/view_approval",
  singleAccountVerificationAPI:
    "api/payment/accountNumberValidation/singleAccountValidation",
  login: "api/login",
  billUpload: "DebitTransaction/Rpt/billUpload",
  fetchAllData: "api/commonUtility/fetchAllData",
  findData: "api/commonUtility/find",
  deleteRecord: "api/commonUtility/deleteRecord",
  DtFileUpload: "DebitTransaction/DtUpload/DtFileUpload",
  downloadDisbursementReports:
    "/reports/DisbursementDownlaod/downloadDisbursementReports",
  downloadPicklistReject:
    "DebitTransaction/DtUpload/downloadPicklistReject?batch_id=",
  contractDetails: 'reports/Payment/fetchContractDetailsWithStatus',
  upldateStatus: "api/payment/paymentReports/updateStatus",
  createUser: "api/commonUtility/createData",
  createVendor: "api/commonUtility/createVendor",
  updateUser: "api/commonUtility/updateData",
  fetchPayment: "api/payment/paymentReports/list",
  manualMarking: "api/payment/manualMaking/",
  manualMarkingfindCase: "api/payment/manualMaking/find_case",
  manualMarkingfindUpCase: "api/payment/manualMaking/find_up_case",
  manualMarkingUploadFile: "api/payment/manualMaking/uploadFile",
  manualMarkingUploadUPFile: "api/payment/manualMaking/uploadUPFile",
  presignedS3upload: "api/commonUtility/presignedS3upload",
  uploadPreSignedUrl: "api/commonUtility/presignedS3upload",
  sftpUploadApi : "/api/uploadSftp",
  downloadPresignedUrl: "api/commonUtility/presignedS3download",
  badGatewayError: `${process.env.REACT_APP_UI_URL}BadGatewayError`,
  linkExpired: `${process.env.REACT_APP_UI_URL}enach/linkexpired`,
  paymentApiStatusDownloadStatus: "api/payment/paymentApiStatus/downloadStatus",
  apipaymentAccountValidation:
    "api/payment/accountNumberValidation/bulkAccountValidation",
  apicommonUtilityPresignedS3Upload: "api/commonUtility/presignedS3upload",
  DebitTransactionPicklistDownloadReject:
    "DebitTransaction/DtUpload/downloadPicklistReject",
  DebitTransactionPicklistDonloadPicklistReject:
    "DebitTransaction/DtUpload/downloadPicklistReject",
  DebitTransactionDeMergeDownloadDemerge:
    "DebitTransaction/DeMerge/downloadDeMergeSourceSystem",
  debitTransactionDeMergeDemergeSourceSystem:
    "DebitTransaction/DeMerge/deMergeSourceSystem",
  DebitTransactionDeMergeDemergeReject:
    "DebitTransaction/DeMerge/downloadDeMergeRejectFile",
  DebitTransactionMergeDownloadFiveField:
    "DebitTransaction/Merge/downloadFiveField",
  DebitTransactionMergeDownloadMergeData:
    "DebitTransaction/Merge/downloadMergeData",
  DebitTransactionRptApproveMergeFlag: "DebitTransaction/Rpt/approveMergeFlag",
  DebitTransactionRptRejectMergeFlag: "DebitTransaction/Rpt/rejectMergeFlag",
  debitTransactionRptDownloadRptSuccess:
    "DebitTransaction/Rpt/downloadRptSuccess",
  DebitTransactionRptDownloadRptReject:
    "DebitTransaction/Rpt//downloadRptReject",
  DebitTransactionDeMergeFetchManualCount:
    "DebitTransaction/DeMerge/fetchManualCount",
  DebitTransactionMergeFetchMergeOffsetStatus:
    "DebitTransaction/Merge/fetchMergeOffsetStatus",
  ReportsMandateRegistrationdownaloadMandateRegistrationRevSftpHdfc:
    "reports/MandateRegistration/downaloadMandateRegistrationRevSftpHdfc",
  reportsRegistrationBankingfetchRegistrationBankingReports:
    "reports/RegistrationBanking/fetchRegistrationBankingReports",
  ApipaymentApiSTatusFetchApiLogs: "api/payment/paymentApiStatus/fetchApiLogs",
  ApipaymentApiStatusDownloadReports:
    "api/payment/paymentApiStatus/downloadReports",
  ReportsDisbursementDownloadFetchDisbursementReports:
    "reports/DisbursementDownlaod/fetchDisbursementReports",

  ApiPaymentPaymentApiStatusDownloadStatus:
    "api/payment/paymentApiStatus/downloadStatus",
  rptFileUpload: "DebitTransaction/Rpt/rptFileUpload",

  ApiV2Mandate_registrationSearch: "api/v2/mandate_registration/search",
  ApiCommonUtilityPresignedS3Uppload: "api/commonUtility/presignedS3upload",
  ApiPaymentReportGenerateFile: "api/payment/paymentReports/generateFile",
  ApiPaymentReportPaymentViaApi: "api/payment/paymentReports/paymentViaApi",
  limit: 10,
  count: 500,
  getXLSXFile: "api/commonUtility/getExcelFile",
  ApiSingleValidationTokenGenerate:
    `${process.env.REACT_APP_PAYMENT_API_URL}/api/user/token/generate`,
  ApiFindByAllBatchId:
    `${process.env.REACT_APP_PAYMENT_API_URL}/api/account_number_validation/findallbybatchid?batch_id=`,
  DtMerge: "DebitTransaction/Merge/DtMerge",
  commonSearch: "api/commonUtility/search?query=",
  MicrBulkUpload: "api/master/micr/createBulkMicr",
  apiuser: "api/apiuserkey?keyLength=",
  changePassword: "change password",
  DtDemerge: "DebitTransaction/DtUpload/DeMergeFileUpload",
  ioToken: "api/iotoken",
  IOURL: `https://sxiop.tatacapital.com?token=`,
  scanBaseDownloadFile: "reports/scanBaseReport/downloadFile?file_name==",
  OpenMandateReport: "api/open_mandate/openMandateReport",
  OpenMandateReportDownload: "api/open_mandate/openMandateReportDownload",
  RPT_OTU: '/DebitTransaction/Rpt/rptotu',
  DebitTransactionDeMergeMandateReg:'DebitTransaction/DeMerge/downloadphysicalDemergeRej',
  mandateRegistrationUpload:'DebitTransaction/DeMerge/mandateRegistrationDemerge',
  fetchStatusBatchId:"api/payment/paymentApiStatus/apiStatusBatch",
  AUTO_PAYMENT_TOGGLE:"api/payment/paymentReports/autoPaymentToggle",
  CREATE_AUTOPAY_MASTER:"api/payment/paymentReports/createAutoPayMaster",
  regularPaymentToggleApiStatus:"api/payment/paymentReports/toggleApiStatus",
  UPI_PROCESS_CSV_FROM_S3:"/DebitTransaction/UPI/processCsvFromS3",
  bulkCreate: "api/commonUtility/bulkCreate",
  bulkCreateAccntValidationVendor: "api/commonUtility/bulkCreateAccntValidationVendor",
};

export const ModuleName = {
  RPTProvisionUpload: "RPT Provision Upload",
  BILL_UPLOAD: "Bill Upload",
};

export const enachApiConstants = {
  tokenGenerate: "api/user/token/generate",
  fetchMaxBatchId: "api/mandate_registration/findMandateBatchId",
  fetchBatch: "api/mandate_registration/fetchBatch",
  mandateRegistration: "api/v2/mandate_registration/save_emandate",
  bulkMandateRejectedRecords:
    "api/v2/bulk_mandate_registration/rejectedreports",
  bulkMandateSuccessRecords: "api/v2/bulk_mandate_registration/successReports",
  bulkMandateSMSReports: "api/v2/bulk_mandate_registration/smsReports",
  bulkMandateCreate: "api/v2/bulk_mandate_registration/bulkUpload",
  saveLogs: "api/v2/mandate_registration/enachSaveLogs",
  updateReversalStatus: "/api/payment/paymentReversal/update_reversal?key=",
  swapMandateCreate: "api/v2/mandate_registration/swapReversalFileUpload",
};

export const bankListAttributes = [
  "nupay_bank_id",
  "bank_code",
  "bank_id",
  "debit_card_emandate_id",
  "bank_name",
  "nupay_debit_flag",
  "nupay_netbanking_flag",
  "bank_id",
  "amount_to_pay",
  "aadhar_bank_code"
];

export const admin = "admin";

export const flags = [
  {
    key: "Yes",
    value: 1,
  },
  {
    key: "No",
    value: 0,
  },
];

export const provisionReportType = [
  {
    key: "Select Report Type",
    value: "",
  },
  { value: "EMANDATE_AND_REVERSAL", key: "EMANDATE AND IO REVERSAL" },
  { value: "OPEN_MANDATE", key: "F1 OPEN MANDATE REVERSAL" },
  {
    value: "OPEN_MANDATE_REGULAR_REPORTS",
    key: "OPEN MANDATE REGULAR REPORTS",
  },
  { value: "F1_HEALTH_REJECT", key: "F1 HEALTH CHECK REJECT REVERSAL" },
  {
    value: "EMANDATE_EXCEPTION",
    key: "EMANDATE EXCEPTION",
  },
  {
    value: "EMANDATE_REVERSAL_FILE_FROM_OTHER_CHANNELS",
    key: "E-MANDATE REVERSAL FILE FROM OTHER CHANNELS",
  },
  {
    value: "SWAP_PROVISION",
    key: "SWAP PROVISONAL CASES",
  },
  {
    value: "NOT_BANKED_PROVISIONAL_UPLOAD",
    key: "NOT BANKED PROVISION UPLOAD",
  },
  {
    value: "BANCS_REVERSAL_REPORT",
    key: "BANCS REVERSAL REPORT",
  },

  // {
  //   value: "TDL_PROVISION_REPORT",
  //   key: "TDL PROVISION REPORT",
  // },
];

export const errMessage = {
  InvalidLoanNumber: "Invalid Loan Number",
  somethingWentWrong: "Something went wrong. Please try again later!",
  noDataFound: "No data found",
  PaymentUnholdSuccessful: "Payment Unholded Successfully",
  PaymentHoldSuccessful: "Payment Holded Successfully",
  PleaseAddValuesInFile: "Please add values in a file",
  ProcessingFile: "File is getting processed ! Please Wait",
  SelectAValue: "Select a value",
  ToDateMustBeGreaterThanFromDate: "To date must be greater than From date ",
  ErrorinCompanyCodeAPI: "Error in Company Code API",
  NoRecordPresent: "No Record Present",
  CannotProceedwithoutTermsAndConditions:
    "Cannot Proceed without Terms & Conditions",
  SelectAuthenticationMode: "Please Select Authentication Mode !",
  InternalServerError: "Internal Server Error",
  invalidFile: "Please enter a valid file",
  uploadFileAndSelectValue: "Please Upload a file and select a value",
  fileUploadFailed: "File Upload Failed",
  fileDownloadFailed: "File Download Failed",
  EnterBatchIdAndLoanNumber: "Enter batch Id and loan number",
  EnterJournalNoAndLoanNumber: "Enter Payment Ref No and loan number",
  uploadFile: "Please Upload a File",
  uploadFileCorrectCombination:
    "Choose correct combination for Uploading Files",
  FileDownloadFailed: "File Download Failed",
  NoRecordPresentWithinSelectedDate: "No Record Present within selected Date",
  uploadFileWithXlsxFormat: "Please upload file with .xlsx format",
  fillAllFields: "Fill all required fields",
  noDataForSelectedMandateType: "No data is present for selected mandate type",
  conpanyCodeSponsorBankErr: "Select Company Code and Sponsor Bank",
  duplicateFileName: "Found Duplicate File Name",
  FoundSpaceInFileName:'Please remove spaces from filename'
};
export const fetchBankDetailsAttributes = [
  "branch_code",
  "bank_name",
  "city",
  "micr_code",
  "ifsc_code",
];

export const fetchBankCodeAttributes = ["bank_code", "bank_name", "upi_flag"];
export const reportConstants = {
  fetchDisbursementTableReports:
    "reports/DisbursementTable/fetchDisbursementTableReports",
  eMandateAuditReport: "reports/EmandateAudit/fetchEmandateAuditReports",
  enachAdoptionReport: "reports/EnachAdoption/fetchEnachAdoptionReport",
  downloadPaytmReversalFile: "/api/payment/paymentReversal/downloadPaytmReversalFile?",
  enachAdoptionDailyReport:
    "reports/EnachAdoption/fetchEnachAdoptionDailyReport",
  swapEnachReports: "reports/EnachAdoption/fetchEnachSwapReport",
  swapMandateBlockCases: "reports/EnachAdoption/fetchEnachSwapReport",
  enachNotBankedReports: "reports/EnachnotBanked/fetchEnachnotBankedReports",
  enachFeedBackReports:
    "reports/EnachFeedbackReports/fetchEnachFeedbackReports",
  enachUPIReports: "reports/EnachUPIReports/fetchEnachUPIReports",
  enachReportDownload:
    "/reports/AccountValidation/fetchAccountValidationReports",
  account_validation_report_creation:
    "https://8o1smp8l30.execute-api.ap-south-1.amazonaws.com/prod/reports",
  fetchEachEnachAdoptionReport:
    "reports/EnachAdoption/fetchEachEnachAdoptionReport",
  downloadBatchReport: "/api/payment/paymentReversal/downloadBatchReport?",
  downloadBulkReport: "api/payment/paymentReversal/downloadbulkReports?",
  downloadReversalFile: "/api/payment/paymentReversal/downloadReversalFile?",
  accountNumberValidationDownload:
    "/api/payment/accountNumberValidation/download?",
  provisionReport: "reports/F1Reversal/fetchF1ReversalReports",
  provisionAnalysisReport:
    "reports/provisionReport/downloadProvisionAnalysisReports",
  downloadPaymentReport: "reports/Payment/fetchPaymentReports",
  downloadDisbursementReport: "",
  paymentReports : "https://8o1smp8l30.execute-api.ap-south-1.amazonaws.com/prod/reports"
}

export const enachTechProcessConstants = {
  createHashValue: "tech_process_v2/createHashValue",
  fetchEnachRecords: "tech_process_v2/fetchRecordsByKey",
  decodeToken: "tech_process_v2/decodeToken",
  redirectNupay: "tech_process_v2/redirectNupay",
  tpslResponseStatus:
    `${process.env.REACT_APP_API}/tech_process_v2/e_mandate/v2/response_status`,
  responseStatus: `${process.env.REACT_APP_UI_URL}/responsestatus`,
  enachFeedbackUrl: "https://qdeg.in/5K2qabC",
  txn_id: "Sameertest123",
  transaction_type: "IMPS",
  acct_validation_mode: "SINGLE",
  upiTransactionPage: `${process.env.REACT_APP_UI_URL}/tech_process_v2/e_mandate/upiTransaction`,
  registerMandate: `${process.env.REACT_APP_UI_URL}/tech_process_v2/e_mandate/auth/find`,
  upiTransactionPollingPage: `${process.env.REACT_APP_UI_URL}/tech_process_v2/e_mandate/upiTransactionPollingPage`,
  upiTransactionProcess: "api/mandate/upiTransaction",
  webSocketForWebhook:
    `${process.env.REACT_APP_WEBSOCKET_URL}/ws`,
  upiResponseStatus: "tech_process_v2/upiResponseStatus",
  getProcessCounter : 'tech_process_v2/getCounter'

  
};

export const EnachVendorTypes = {
  UPI: "PAYTM_UPI",
  NUPAY: "NUPAY",
  TPSL: "TPSL",
  ICICI_UPI:"ICICI",
  UPI: "UPI",
};

export const tokenConstants = {
  enach_token_data: {
    user_id: "SYSTEMX_EMANDATE",
    key: "LellV+1mdHw0CnZ0Uwjnhg==",
  },
};

export const enachStatusAttributes = [
  "source_system_unique_number",
  "web_top",
  "los_id",
  "source_system",
  "account_holder_name",
  "account_number",
  "loan_no",
  "mandate_request_id",
  "umrn_no",
  "response_rejection_reason",
  "enach_s3_logs_key",
  "mandate_status",
  "createdAt",
  "updatedAt",
  "logs_value",
  "enach_initiated_time",
  "enach_response_time",
  "enach_s3_logs_key",
  "product_type",
  "vendor_type",
  "auth_mode"
];

export const bulkDownloadFlags = {
  SUCCESS: "SUCCESS",
  REJECT: "REJECT",
  SMS_YES: "SMS_YES",
  SMS_NO: "SMS_NO",
  PROCCESSING: "Processing",
};

export const paymentBatchTableAttributes = [
  "batch_id",
  "upload_date_time",
  "file_name",
  "total_count",
  "sucess_count",
  "reject_count",
  "user_name",
  "createdAt",
];

export const viewMandateAttributes = [
  "id",
  "batch_id",
  "loan_no",
  "product_type",
  "mandate_status",
];

export const paymentBatchTableOrder = [["batch_id", "desc"]];
export const ByIdTableORder = [["id", "DESC"]];

export const manualMandateAttributes = [
  "mandate_status",
  "enach_mandate_id",
  "mandate_type",
  "source_system",
  "vendor_type",
  "source_system_unique_number",
  "nupay_unique_url",
  "web_top"
];

export const registerMandateManualAttribute = [
  "mandate_status",
  "company_code",
  "response_rejection_reason",
  "company_code",
  "source_system_unique_number",
  "feedback_status",
  "vendor_type",
  "enach_mandate_id",
  "enach_rejected_reason",
  "umrn_no"
];

export const companyAttributes = ["company_code", "company_name"];

export const getBankDetailsSingleAttributesMicr = [
  "ifsc_code",
  "city",
  "branch_code",
  "bank_name",
];

export const getBankDetailsSingleAttributesIfsc = [
  "city",
  "branch_code",
  "bank_name",
  "micr_code",
];
export const reportStatusAttributes = [
  "id",
  "module_type",
  "sub_module_type",
  "status",
  "metadata",
  "s3_path",
  "created_by",
  "createdAt",
  "updatedAt",
];
export const reversalApiBasedAttributes = [
  "module_type",
  "sub_module_type",
  "status",
  "metadata",
  "s3_path",
  "created_by",
  "createdAt",
  "updatedAt",
];
export const reversalPayTmBasedAttributes = [
  "module_type",
  "sub_module_type",
  "status",
  "metadata",
  "s3_path",
  "created_by",
  "createdAt",
  "updatedAt",
];

export const AccountValidationStatusDataFetch = [
  {
    column: "file_name",
    operator: "ne",
    value: " ",
  },
];

export const tableNamePaymentConstants = {
  Company: "company",
  User: "user",
  Role: "role",
  TCLBranch: "io_branchMaster",
  UserType: "userType",
  ApiUserRegistration: "api_user",
  paymentResponseStatus: "payment_response_status",
  BulkMandateReg: "bulk_mandate_reg",
  AccountType: "accountType",
  new_scan_reg_bank_list: "new_scan_reg_bank_list",
  accountNumberValidationStatus: "account_number_validation_status",
  PaymentType: "payment_type",
  micr: "micr",
  ifsc: "ifsc",
  ManualMandate: "manualMandate",
  Frequency: "frequency",
  BusinessIdMaster: "business_id_master",
  SourceSystemMaster: "source_system_master",
  SourceSystem: "source_system",
  sponsorBankPaymentTypeMaster: "sponsor_bank_payment_type_master",
  rptReversalRejectionMaster: "rptReversalRejectionMaster",
  recieptTypeMaster: "receipt_type_master",
  registrationRejection: "registration_rejection",
  Product: "product",
  swapMndBlkFilesLog: "swap_mnd_blk_files_log",
  Category: "category",
  Rejection: "rejection",
  Micr: "micr",
  SponsorBank: "sponsorBank",
  VenderName: "venderName",
  mandateType: "mandateType",
  IntegrationBillUploadResponse: "integrationBillUploadResponse",
  ScanRegRevFileLog: "scan_reg_rev_file_log",
  System: "system",
  SubProduct: "subProduct",
  MandateType: "mandateType",
  IoBranchMaster: "io_branchMaster",
  BaNCS: "bancs_bank_master",
  Scheme: "scheme_master",
  NU_bank_list: "NU_bank_list",
  accountNumberValidation: "account_number_validation",
  account_validation_vendor: "account_validation_vendor",
  Account_validation_vendor_master: "account_validation_vendor_master",
  disbursement_vendor: "disbursement_vendor",
  disbursement_vendor_master: "disbursement_vendor_master",
  reportStatus: "reportStatus",
  fileUploadLog: "file_upload_log",
  scanSftpMergeLog: "scan_merge_sftp_push_log",
  debitTranRegFileUploadStatus: 'debit_tran_reg_file_upload_status',
  transactionRejection: "debitTransactionRejectionCode",
  BANK_MASTER_AUTOPAY:'bank_master_autopay',
  FILE_EXECUTION_LOGS:"file_execution_logs",
  AUTH_MODE_STATUS:"auth_mode_status",
  BANKING_TEAM_NOTIFICATION: "banking_team_notification_tbl"
};

export const singleValidationStatusWhereQuery = {
  acct_validation_mode: "SINGLE",
  source_sytem: "MANUAL",
};

export const singleValidationStatusAttributes = [
  "source_sytem",
  "company_code",
  "micr_code",
  "ifsc_code",
  "customer_name",
  "account_number_as_per_mandate",
  "account_type",
  "amount",
  "txn_status_desc_tc",
  "txn_status_desc",
  "txn_utr",
  "txn_imps_bene_name",
  "txn_payment_date",
  "txn_status_code",
  "createdAt",
];

export const mandateAttributes = [
  "id",
  "batch_id",
  "file_name",
  "user_name",
  "upload_date_time",
  "upload_status",
  "total_count",
  "success_count",
  "rejected_count",
  "sms_no",
  "sms_yes",
];

export const DtRepresentationTableOrder = [["id", "desc"]];

export const mandateTypeAttributes = [
  "source_system_unique_number",
  "bitly_sms_status",
];

export const attributePaymentConstants = {
  mandateStatus: "mandate_status",
  Company: "company_name",
  SourceSystemName: "source_system_name",
  PaymentType: "payment_mode_name",
  BusinessIdMaster: "business_id",
  sponsorBankPaymentTypeMaster: "bank_name",
  recieptTypeMaster: "name",
  Product: "product_code",
  AccountName: "account_name",
  VenderName: "venderName",
  productCode: "product_code",
  productName: "product_name",
  upiFlag: 'upi_flag',
};

export const successMsg = {
  EnachBulkUpload:
    "File Uploaded Successfully. Please refresh to see the File Record Status !",
  fileUploadedSuccessfully: "File Uploaded Successfully",
  recordSentSuccessfully: "Record sent for payment Successfully",
  PaymentHoldedSuccessfully: "Payment Holded Successfully",
  PaymentRejectSuccessfully: "Payment Rejected Successfully",
  PaymentRegularSuccessfully: "Payment Moved to Regular Successfully",
  FileDownloadSuccessfully: "File is downloaded successfully",
  SchemaUpdatedSuccessfully: "Scheme Updated Successfully",
  SchemaCreatedSuccessfully: "Scheme Created Successfully",
  SourceSystemUpdatedSuccessfully: "Source System Updated Successfully",
  SourceSystemCreatedSuccessfully: "Source System Created Successfully",
  CompanyUpdatedSuccessfully: "Company Updated Successfully",
  CompanyCreatedSuccessfully: "Company Created Successfully",
  UserCreatedSuccessfully: "User Created Successfully",
  UserUpdatedSuccessfully: "User Updated Successfully",
  RoleCreatedSuccessfully: "Role Created Successfully",
  RoleUpdatedSuccessfully: "Role Updated Successfully",
  UserTypeCreatedSuccessfully: "UserType Craete Successfully",
  UserTypeUpdatedSuccessfully: "UserType Updated Successfully",
  ApiUserRegistrationCreatedSuccessfully: "Api User Created Successfully",
  ApiUserRegistrationUpdatedSuccessfully: "Api User Updated Successfully",
  MandateTypeCreatedSuccessfully: "Mandate Type Created Sucessfully",
  MandateTypeUpdatedSuccessfully: "Mandate Type Updated Sucessfully",
  FrequencyCreatedSuccessfully: "Frequency Created Successfully",
  FrequencyUpdatedSuccessfully: "Frequency Updated Successfully",
  SystemCreatedSuccessfully: "System Created Successfully",
  SystemUpdatedSuccessfully: "System Updated Successfully",
  EnachVendorCreatedSuccessfully: "Enach Vendor Created Successfully",
  EnachVendorUpdatedSuccessfully: "Enach Vendor Updated Successfully",
  AccountValidationVendorCreatedSuccessfully:
    "Account Validation Vendor Created Successfully",
  AccountValidationVendorUpdateSuccessfully:
    "Account Validation Vendor Updated Successfully",
  DisbursmentVendorUpdateSuccessfully:
    "Disbursment Vendor Updated Successfully",
  DisbursmentVendorCreatedSuccessfully:
    "Disbursment Vendor Created Successfully",
    // enach auth mode messages
  AuthModeUpdatedSuccessfully: 'Authentication mode updated successfully',
  AuthModeCreatedSuccessfully: 'Authentication mode created successfully',
  NotificationCreatedSuccessfully: 'Notification created successfully',
  NotificationUpdatedSuccessfully: 'Notification updated successfully',
  AccountTypeCreatedSuccessfully: "Account Type Created Successfully",
  AccountTypeUpdatedSuccessfully: "Account Type Updated Successfully",
  BaNCSCreatedSuccessfully: "BaNCS Sponsor Bank Master created Successfully",
  BaNCSUpdatedSuccessfully: "BaNCS Sponsor Bank Master Updated Successfully",
  PaymentCreatedSuccessfully: "Payment Created Successfully",
  PaymentUpdatedSuccessfully: "Payment Updated Successfully",
  SourcePaymentCretaedSuccessfully: "Sponsor Payment Bank Created Successfully",
  SourcePaymentUpdatedSuccessfully: "Sponsor Payment Bank Updated Successfully",
  BusinessIdMasterCreatedSuccessfully: "Business Id  Created Successfully",
  BusinessIdMasterUpdatedSuccessfully: "Business Id  Updated Successfully",
  ReceiptCreatedSuccessfully: "Receipt Type Created Successfully",
  ReceiptUpdatedSuccessfully: "Receipt Type Updated Successfully",
  VenderNameUpdatedSuccessfully: "Vender Updated Successfully",
  VenderNameCreatedSuccessfully: "Vender Created Successfully",
  fileUploadedSuccessfully: "File Uploaded Successfully",
  recordSentSuccessfully: "Record sent for payment Successfully",
  PaymentHoldedSuccessfully: "Payment Holded Successfully",
  CreatedSuccessfully: "Created Successfully",
  UpdatedSuccessfully: "Updated Successfully",
  RejectionLIstUpdatedSuccessfully: "Rejection LIst Updated Successfully",
  RejectionLIstCreatedSuccessfully: "Rejection LIst Created Successfully",
  RepresentationApprove: "Successfully Approved",
  RepresentationRejected: "Successfully Rejected",
  Deleted: "Successfully Deleted",
};

export const errorMsg = {
  UserAlreadyExists: "User already exists",
};

export const downloadIndusHdfcReports = (fileName, bankName) => {
  return `api/payment/paymentReports/downloadIndusHdfcReports?file_name=${fileName}&bank_name=${bankName}`;
};

export const fetchIndusHdfcReports = (FromDate, ToDate, Sponsor, user_name) => {
  return `api/payment/paymentReports/fetchIndusHdfcReports?from_date=${FromDate}&to_date=${ToDate}&bank_name=${Sponsor}&user_name=${user_name}`;
};

export const toastIds = {
  SingleAccountVerificationFailed: "singleAccountVerificationFailed",
  recordSentSuccessfully: "recordSentSuccessfully",
  SingleAccountVerificationPass: "singleAccountVerificationPass",
  FileUploadSuccess: "fileUploadSuccess",
  FileDownloadSucess: "filesDownloadSuccess",
  selectVal: "selectVal",
  PaymentHoldedSuccessfully: "PaymentHoldSuccess",
  PaymentUnholdedSuccessfully: "PaymentUnholdSuccess",
  FileUploaderr: "fileUploaderr",
  SelectAuthenticationMode: "Please Select Authentication Mode !",
  DataError: "DataError",
  CannotProceedwithoutTermsAndConditions:
    "Cannot Proceed without Terms & Conditions",
  rptbillUpload: "rptbillUpload",
  InternalServerError: "Internal Server Error",
  SomethingWentWrong: "SomethingWentWrong",
  assignedRoleSuccess: "Role Assigned Success",
};

export const updateStatusApi = "api/payment/paymentReports/updateStatus";

export const paymentType = {
  regular: "regular",
  hold: "hold",
};

export const FOMRS = {
  ADD: "ADD",
  EDIT: "EDIT",
  VIEW: "VIEW",
  DELETE: "DELETE",
};

export const STATUS = {
  SUCCESS: 200,
  NOT_FOUND: 404
};

export const isActiveDropdown = [
  { key: "Select an option", value: "" },
  { key: "Yes", value: "1" },
  { key: "No", value: "0" },
];

export const isActiveDropdowns = [
  { key: "Select an option", value: "" },
  { key: "Yes", value: 1 },
  { key: "No", value: 0 },
];

export const techNotationDropdown = [
  { key: "Select an option", value: "" },
  { key: "Technical", value: "Technical" },
  { key: "Non-Technical", value: "Non-Technical" },
  // { key: 'No value to be selected', value: 'No value to be selected' },
];
export const vendorTypeDropdown = [{ key: "NUPAY", value: "NUPAY" }];

export const vendorTypeDropDownForValidation = [
  { key: "Select an option", value: "" },
  { key: "Cashfree", value: "CASHFREE" },
  { key: "Karza", value: "KARZA" },
];
export const manualMarkingStates = {
  failed: "Failed",
  moveToException: "Fail and Move to Exception",
};

export const singleAccountVerificationAPIMessage = {
  success: "The account has been verified successfully",
  fail: "The account has not been verified. Please try again",
  itemsProcessed: "All item have been processed",
  fileUploaded: "File Uploaded",
};

export const userMasterAPIMessage = {
  assignedRoleSuccess: "Role assigned successfully",
};
export const UserId = "SYSTEMX_EMANDATE";
